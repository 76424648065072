import React from "react"

export const TITLES = {
  related_services: {
    title: (
      <>
        related <strong>services</strong>
      </>
    ),
    strongText:
      "Plan your expenses and define the roadmap before the first line of code created",
    uppercase: (
      <>
        increase business
        <br /> success
      </>
    ),
    withIndent: true,
  },
  plan: {
    title: (
      <>
        <strong>Design, Architect, Estimate, and Plan </strong> your
        <br />
        project with our professionals
      </>
    ),
    strongText: "we have more than 10 years of estimation practice",
    uppercase: (
      <>
        we have 5+ years
        <br /> of using a calibration estimation AI model
      </>
    ),
    withIndent: true,
  },
}

export const SERVICES_LIST = [
  {
    id: 1,
    title: "Digital Product Design",
    text:
      "We create a design that users want to interact with. Our experienced designers create beautiful digital designs for web and mobile applications with intelligent user experience.",
    link: "/product-design/",
    icon: "icon-pencil",
  },
  {
    id: 2,
    title: "MVP Development",
    text:
      "Have a business idea? Start with a Minimal Valuable Product. It allows you to test the concept’s viability and saves you from the hassle of significant investments in creating a full-fledged digital product.",
    link: "/mvp-development/",
    icon: "icon-rocket",
  },
]

export const ESTIMATION_STEPS = [
  { id: 1, title: "Upload your Brief" },
  { id: 2, title: "Discovery Interview" },
  {
    id: 3,
    title: <>System Design &&nbsp;Architecture Session</>,
  },
  {
    id: 4,
    title: <>Project Planning &&nbsp;Estimation Session</>,
  },
]
