import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import {
  TITLES,
  SERVICES_LIST,
  ESTIMATION_STEPS,
} from "../data/software-project-estimation"
import { IMG } from "../const"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/software-project-estimation.module.scss"
import Headline from "../components/headline"

const TitleBlock = loadable(() => import("../components/title-block"))
const ServiceCard = loadable(() =>
  import("../components/carousels/ServiceCard")
)
const ContactForm = loadable(() => import("../components/contact-form"))

const SoftwareProjectEstimation = () => (
  <Layout
    pageTitle="Software Project Estimation"
    metaDescription="Get an accurate software project estimation 10 times faster using a unique AI-based solution covering data from 500+ software project estimates!"
    defaultImage={`${IMG.THUMBNAILS}/thumbnail-software-project-estimation.jpg`}
  >
    {({ width, handleOpen, handleFirstTimeUser }) => {
      return (
        <>
          <main
            className={cx(styles.softwareProjectEstimationBlock, "common-kit")}
          >
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className={"strongText"}>
                      in most cases, your first step should be finding and
                      utilizing a
                    </p>
                    <h1 className={"withIndent"}>
                      <strong>Software project</strong> estimation service
                    </h1>
                    <div className={"rightColumn"}>
                      <div>
                        <p>
                          Get an accurate project estimation 10 times faster
                          using a unique AI-based solution covering data from
                          500+ software project estimates.
                        </p>
                      </div>
                      <div>
                        <Link
                          to="#contact-form"
                          className="goldFatLink softestimate-estimate"
                        >
                          Project Estimate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Headline>

              {width >= 1020 && (
                <section className={styles.screenshots}>
                  <div className={styles.screenshots__container}>
                    <div className={styles.screenshot__1}>
                      <picture>
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject.avif,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject@2x.avif 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject.webp,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject@2x.webp 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject.png,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject@2x.png 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject@3x.png 3x`}
                        />
                        <img
                          src={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject@3x.png`}
                          alt="Screen of estimation"
                          width="786"
                          height="433"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div className={styles.screenshot__2}>
                      <picture>
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2.avif,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2@2x.avif 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2.webp,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2@2x.webp 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2.png,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2@2x.png 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2@3x.png 3x`}
                        />
                        <img
                          src={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-2@3x.png`}
                          alt="Screen of estimation"
                          width="338"
                          height="347"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div className={styles.screenshot__3}>
                      <picture>
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3.avif,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3@2x.avif 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3.webp,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3@2x.webp 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3.png,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3@2x.png 2x,	${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3@3x.png 3x`}
                        />
                        <img
                          src={`${IMG.SOFTWARE_PROJECT_ESTIMATION}/software-poject-3@3x.png`}
                          alt="Screen of estimation"
                          width="240"
                          height="343"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </section>
              )}

              <section className={cx("section", styles.textOutline)}>
                <p className={styles.textOutline__dash}>
                  Our team offers you an accurate and reliable budget and
                  timeline estimation fast. History-based estimation will be
                  provided within days, not weeks.
                </p>
              </section>

              <section className={cx(styles.optionsListBlock, "section")}>
                <div className={styles.strongText}>
                  <h2>How it works</h2>
                </div>
                <ul className={styles.optionsList}>
                  {ESTIMATION_STEPS.map(option => (
                    <li key={option.id}>
                      <h3>{option.title}</h3>
                      <div className={styles.optionsList__decorativeLine} />
                    </li>
                  ))}
                </ul>
              </section>

              <section className="section">
                <TitleBlock options={TITLES.plan} />
                <div className={styles.twoColumns}>
                  <p>
                    We help entrepreneurs, founders, and consultants who want to
                    estimate the cost of software development. Software Project
                    Estimation Service is a unique solution based on data from
                    500+ projects that help our managers calculate the exact
                    cost and duration of a project.
                  </p>
                  <p>
                    We aren’t like other software development companies that
                    only promise to develop a product cheaply and in a short
                    period. Instead, we determine the actual cost and duration
                    of the project as well as suggest the most appropriate
                    technology, system design, proper project planning, and ways
                    to reduce costs without compromising the quality of the
                    system.
                  </p>
                </div>
              </section>

              <section className={cx("section", styles.banner)}>
                <div>
                  <div className={styles.banner__img} />

                  <div className={styles.banner__text}>
                    <h2>fixed cost project development</h2>
                    <p>
                      As soon as we estimate a project, it is developed at a
                      fixed price.
                    </p>

                    <div className={styles.banner__buttons}>
                      <button
                        type="button"
                        className="goldFatLink softestimate-fiftyoff"
                        onClick={handleFirstTimeUser}
                      >
                        First time user 50% off
                      </button>
                      <button
                        type="button"
                        className={cx("softestimate-estimate-two", styles.link)}
                        onClick={handleOpen}
                      >
                        Project Estimate
                      </button>
                    </div>
                  </div>
                </div>
              </section>

              <section className={cx(styles.services, "section")}>
                <TitleBlock options={TITLES.related_services} />
                <div className={styles.servicesWrapper}>
                  <div>
                    {SERVICES_LIST.map(item => (
                      <ServiceCard key={item.id} item={item} />
                    ))}
                  </div>
                </div>
              </section>
            </article>
          </main>
          <section className="footerForm common-kit">
            <div className="section" id="contact-form">
              <ContactForm formStatID="CommonFooter" />
            </div>
          </section>
        </>
      )
    }}
  </Layout>
)

export default SoftwareProjectEstimation
